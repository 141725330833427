 
*{
    margin: 0;
    padding: 0;
    max-width: 100vw;
    
  }

h1{
  text-align: center;
}

html {
  scroll-behavior: smooth;
}
