 

.div-container {

    overflow: hidden;
    display: flex;
    position: relative;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
  }

  .outercontainer img {

    display: block;
    position: relative;
    top:20%;
    height:250px;
    width: auto;
    z-index: 3;
    margin: 0 auto;
   
  }
  
.my-div{
    min-width: 40px; /* Adjust the width as needed */
    min-height: 40px; 
    max-width: 40px; /* Adjust the width as needed */
    max-height: 40px; 
    
}

 

  .white {
    /* Adjust the height as needed */
    background-color: rgba(255, 255, 255, 0)

     /* Adjust the background color as needed */
  }

  .black {background-color:rgba(0, 0, 0, 0.343);
     }
  .gradient{
    position: absolute;
    width: 100%; /*Full width of its container */
    height: 84px; /* Adjust this value to your desired height */  
     
    background-color: white
  }

 
@media(max-width:800px)
{  .outercontainer img{
  height:190px;
  width: auto;
} }