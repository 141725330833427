.ContactContainer{
    margin:100px 50px;
    font-family: "Oswald", sans-serif;
    
}
.LocationFlex{
    justify-content: space-between;
}

.ContactContainer h1{
    font-size: 50px;
    margin: 30px 0;
    text-align: left;
    
}

.ContactContainer iframe{
    border: 2px solid black;    
}

.ContactContainer img{
    width:50px;    
    height: auto;
    display: block;
    margin: 0 10px;

}

.ContactContainer img:hover{
    cursor: pointer;
    transform: scale(1.2);

}

.SocialMedia{
  
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    justify-content: space-evenly;

    margin: 2em 0;
}
.SocialMediaIcons{
    width:50%;
    display:flex;
    justify-content: space-evenly;
}



 

.ContactInformation p {
    margin-left: 10px
}
 
.ContactInformation{
    justify-content: space-between;
}
.ContactFlex, .LocationFlex{
display: flex;
flex-wrap:wrap
}

.ContactFlex >*, .LocationFlex >*{
    width: 40vw;
    display:flex;
    flex-direction: column;
    }

.Directions{
    width: 100%; 
    margin:2rem 0;
    font-size: 1.4rem;
}

.Directions p{
   margin:0
}


.Phone, .Email, .Address {
    display:flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5rem;
}
.MapContainer{
    width:50%;
    
}
 
.Map{
    width:350px;
    height:350px
}
@media(max-width:800px){

    .Map{
        width:300px;
        height:300px
    }

    .Directions {
        width: 100%;
        margin: 2rem 0;
    }

    .Directions h3 {
        font-size: 1.5rem;
    }
    .Directions p {
        font-size: 1.1rem;
    }

    .Phone, .Email, .Address {
        display:flex;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1rem;
    }

    .MapContainer {
        width: 100%;
    }
    .ContactContainer {
        margin: 100px 20px;
        font-family: "Oswald", sans-serif;
    }

    .ContactContainer h1 {
        font-size: 1.9rem;
    }

      .LocationFlex >* {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}