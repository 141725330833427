.ScheduleContainer{
 
 padding-top:100px;
 margin: 0 4vw;
 
}

.ScheduleTitle h1{
   
 
  margin: 0 auto;
  font-size: 1.2em;
  
} 

.icon-container{
  display:flex;
  align-items: center;
  justify-content: center;
  padding:100px;
  flex-wrap: wrap;
}

.calendar-icon {
  color: #000000; /* Change this to your desired color */
  font-size: 100px; /* Change this to your desired size */
}

.grid-container {
   
  display: flex;
 
  justify-content:  space-evenly;
  justify-content: flex-start;
 
 
}

.grid-item {
  flex: 1 1 0; 
  min-width: calc((100% - 120px) / 7);
  background-color: #ffffff;
  text-align: center;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.Weekdays {
  width:90%;
  background-color: rgba(0, 0, 0, 0.805);
  padding: 5px;
  margin:0 auto;
 height:45px;
  font-size: 30px;;
  flex: 0 0 auto;
  color: white;
  border-bottom: black 3px solid;
  display:flex;
  align-items: center;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  
   
  font-style: normal;
}

.Day {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 auto;
  overflow: hidden;
  min-height:300px;
  max-height:300px;
  width: 90%;
  margin: 0 auto;
 
}

.Bjj {
  background-color: rgba(0, 38, 255, 0.883);
}

.Pro {
  background-color: rgba(0, 38, 255, 0.883);
}

.Nogi {
  background-color: rgba(0, 38, 255, 0.883);
   
}

.Openmat {
  background-color: rgba(0, 38, 255, 0.883);
}

.Kids {
  background-color:rgba(0, 38, 255, 0.883);
}


.Bjj, .Pro, .Nogi, .Openmat, .Kids {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 border-radius:5px;;
  color: white;
  margin: 5px;
  padding: 5px;
  border: 1px solid black;
  background-color: rgba(169, 1, 1, 0.75);
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
 
  font-style: normal;
}


.hidden {
  display: none;
}

.Monday{
  justify-content: flex-end;
}

.Tuesday{
  justify-content: space-between;
}

.Kids{
  margin: 20px 5px;
}

.Nogi, .Openmat {
  margin-top: 60px
}

 

@media(max-width:800px){
  .grid-container{
  flex-direction: column;

  }

  .Day{
   
   padding-bottom:50px;
   width: 60%;
    
  
  }

  .Wednesday, .Friday{
 
    display:none;
  }

  .Weekdays{
    margin: 30px auto;

  }
  
  .BottomBorder{
    width:100vw;
    border-bottom:3px solid black;
    margin: 0 auto;
  }
 
 
}