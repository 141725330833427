.FreeClassContainer {
    min-height:100vh;
    padding-top:100px
}

.FreeClassContainer img{
    width:30vw;
   height:auto;
   display:block;
   margin: 0 auto;
 
 
}





.FreeClassContainer h1 {
    margin:50px;
    font-size: 1.5rem;
    font-family: "Oswald", sans-serif;
    text-align: left;
 
}


.Buttons{
 
    width:40vw;
    margin: 0 auto;
    margin-top:2rem;


}

 
.Buttons button {
    background-color: rgb(0, 0, 0);
    font-size: 40px;
    font-family: "Oswald", sans-serif;
    box-shadow: 5px 5px 10px gray;
    margin: 0 20px;
    height:200px;
    width:200px;
    border-radius: 50%;
    color:white;
    border:3px solid white;
 
}

.Buttons button:hover{
  cursor:pointer;
  transform: scale(1.1);
  background-color: rgba(227, 227, 234, 0.501);
  color:black;
  border:2px solid black;

}
.WhyWait{
    font-size: 3.5rem;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

.FirstStep{
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
   
}

.Crimson{
    color: rgb(182, 21, 53);
   
}
.show{
    opacity:1;
    transform: translateX(0);
    transform: translateY(0);
}

.FreeClassButtons {
    margin:20px auto;
    font-family: "Oswald", sans-serif;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 30px;
    background-color: black;
  
}

.FreeClassButtons.white {
    background-color: white;
}
 

.FreeClassHeader{
    transform: rotate(-40deg);
    display:flex;
    justify-content: flex-start;
}

.Jocko{
    border:2px solid black;
    border-radius:10px;
    width:700px;
    height:400px;
    display:block;
    margin:50px auto;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.609);
   
}




.DropdownContainer{
    display: flex;
    align-items: center;
    justify-content: center;

}


.calendly-inline-widget{
    
   margin-top:50px
}




@media(max-width:800px){
 

.Jocko{
    width:99vw;
    height:400px;
}

.WhyWait{
    font-size: 3rem;
 
}

.DropdownContainer{
    flex-direction: column;
}
}