.Footer{
    background-color: black;
    color:white;
    text-align: right;
    font-family: "Roboto Mono", monospace;
    height:70px;
     padding:0 20px;
   
    font-size: 20px;
   
}

 

.big{
    font-size: 50px;
    
}

.Footer{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom:20px
}

.Footer >*{
   margin: 0 5px;
}

 
@media(max-width: 800px){
    .Footer{
  
        font-size: 15px;
       
    }
    
}