.hero-outer-container{
 
    height: 100vh; /* Full viewport height */
    overflow: hidden;
}

.hero-container {
    position: relative;
    width: 100%;
    height: 125%; /* Full viewport height */
    overflow: hidden; /* Hide any overflow */
}

.hero-container video {
    position: absolute;
    border:rgb(0, 0, 0) 4px solid;
    top: -27px;
    left: 50%;
    width: 100%; /* Ensure the video width covers the container */
    height: 100%; /* Ensure the video height covers the container */
    object-fit: cover; /* Scale the video down to fit within the container */
    transform: translate(-50%, -0%); /* Center the video */
}

.ForefrontText {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 0.5rem;
    text-align: center; /* Center text horizontally */
    max-width: 90%; /* Adjust the maximum width as needed */
    opacity: 0;
    transition: transform 3s ease-out, opacity 3s ease-out 1s;   
}

.ForefrontText.visible {
    opacity:1

}

.HeadingText {
   font-size: larger;
    color: white;
    padding: 0.5rem;
    border-radius: 10%;
    width: 100%; /* Make the text block responsive */
    max-width: 600px; /* Set a maximum width for large screens */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.video-overlay {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(130, 130, 130, 0.362); /* Semi-transparent black overlay */
    z-index: 1; /* Ensure the overlay is above the video */
}

.StartLearning {
    display:flex;
    flex-direction:column;
    height:200px;
    width:200px;
    text-transform: uppercase;
    border: 4px rgb(255, 255, 255) solid;
    background-color: rgba(0, 0, 0, 0.303);
   
    padding: 0.5rem;
    border-radius: 50%;
    color: rgb(255, 255, 255);
    box-sizing: border-box; /* Include padding in the element's total width and height */
    display:flex;
    align-items: center;
    justify-content: center;
    font-family: 'Arial Black', sans-serif;

}

.Free img, .Class img{
    display:block;
    height:50px;
    width:auto;
}

 
.StartLearning:hover{
    cursor: pointer;
    transform: scale(1.1);
}

@media(max-width:600px){
    .ForefrontText{
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);}
}