.Navbar {   
   height: 100vh;
   position: absolute;
   width: 100%;
   z-index: 3;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
 }
 
 .heade {
   height: 120px;
   width: 90%;
   top: 0;
   left: 0;
   transition: 0.5s ease-in;
   z-index: 55;
   margin-bottom: 10rem;
 }
 
 .heade .nav-item {
   position: relative;
 }
 
 .heade .nav-item a:hover::before {
   content: '';
   position: absolute;
   bottom: -3px; /* Adjust as needed */
   left: 0;
   right: 0;
   height: 3px;
   width:70%;
   margin: 0 auto;
   background: whitesmoke;
 }

 #secondlogo {
   width: 150px;
   height: auto;
   margin-left: 50px;
   padding-top: 40px;
   position: relative;
   z-index: 999;
  
 }
 
 #CenterLogo {
   height: 120px;
   width: auto;
   margin-bottom:3rem;
 }
 
 .Navbar img {
   display: block;
   height: 40px;
   width: auto;
   z-index: 3;
 }

 .NavItems{
   font-family: "Oswald", sans-serif;
   color: white;
   font-size: 30px;
 }

 .NavItems a{
   font-family: "Oswald", sans-serif;
   color: white;
   font-size: 30px;
   text-decoration: none;
 }
 
 .NavItemz {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   list-style: none;
   padding-top: 2rem;
   width: 80%;
   height: 80%;
   margin-bottom: 6rem;
 }
 

 @media (max-width:800px){
   #secondlogo{
      display: none
    }
 }