.logo:hover {
  cursor: pointer;
}

#logo {
  width: 150px;
  height: auto;
  margin-left: 50px;
  padding-top: 40px;
  position: relative;
  z-index: 999;
}

.nav-menu {
  justify-content: space-between;
}

.header {
  transform: translateY(-150px);
  position: fixed;
  height: 120px;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.5s ease-in;
  z-index: 55;
}

.horizontalnavbar li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-bg {
  transform: translateY(0);
  background: linear-gradient(to bottom, rgb(95, 95, 95) 0%, rgb(0, 0, 0) 100%);
  position: sticky;
}

.horizontalnavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  height: 120px;
  padding: 0rem;
}

.LogoContainer {
  max-width: 33%;
}

.horizontalnavbar img {
  height: 30px;
  width: auto;
}

.header .nav-menu {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header .nav-item {
  cursor: pointer;
  list-style: none;
  font-size: x-large;
  position: relative;
}

.header .nav-item a {
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}

.header .nav-item a:hover::before {
  content: '';
  position: absolute;
  bottom: -3px; /* Adjust as needed */
  left: 0;
  right: 0;
  height: 3px;
 
  background: whitesmoke;
}

.hamburger {
  display: none;
  padding-right: 2rem;
}

.nav-item {
  margin: 0 auto;
}

.NavItems {
  display: flex;
  align-self: flex-end;
  width: fit-content;
}

.NavItems > * {
  padding: 0 2rem;
}

.FreeClassButton {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1300px) {
  .FreeClassAndNav {
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 980px) {
  .header {
    max-width: 100vw;
  }

  .header .horizontalnavbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    display: flex;
    position: fixed;
    left: -120%;
    top: 120px;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.968);
    
    z-index: 50;
    transition: 0.3s;
    width:100vw;
    height:100vh;
  }

  .nav-menu.active {
    left: 0px;
  }

  .NavItems {
    flex-direction: column;
    margin: 0 auto;
    padding-bottom: 5rem
  }

  .nav-item {
    margin: 0.5rem auto;
  }
}
