/* stylesheets/cssfile.css */

.image-container {
    width: 100%; /* Adjust the width as needed */
    height: 300px; /* Adjust the height as needed */
    
    position: relative;
}

.image-container img {
 
    display: block;
    position: absolute;
   
    left: 50%;
    width:2000px;/* Adjust this if you want to maintain aspect ratio */
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover; /* Ensures the image covers the container */
    padding-bottom: 100px;;
}


@media(max-width:800px){

    .image-container img {
    top:50%}
}
