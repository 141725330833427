.get-started-button {
    background-color: #990000; /* Black background */
    color: #ffffff; /* White text */
    font-size: 1.3em;
    font-weight: bold;
    padding: 15px 30px;
    border: 5px solid #ffffff; /* White border */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width:fit-content;
    margin:40px auto;
    white-space: nowrap;
    display: inline-block;
}

.get-started-button:hover {
    background-color: #ffffff; /* White background */
    color: #000000; /* Black text */
    border: 2px solid #ed0000; /* Black border */
}

.get-started-button:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2); /* Black focus shadow */
}


@media(max-width:800px){
   
    .get-started-button {
 
        font-size: 22px;
 
}}