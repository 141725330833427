.DropdownMenu{
    margin: 0 10px;
    height: fit-content;
 
   
    height:100%


 
}
 
label {
     margin-right: 10px; /* Add some space between the label and the dropdown */
  }
  
  select {
      /* Increase the width of the dropdown */
    height: 40px; /* Increase the height of the dropdown */
    font-size: 0.5em; /* Adjust the font size */
    padding: 5px 30px 5px 10px; /* Add padding inside the dropdown */    
    border: 1px solid #ccc; /* Add a border */
    border-radius: 5px; /* Add rounded corners */
    background-color: #fff; /* Background color */
    color: #333; /* Text color */
  }
  
  /* Optional: Style the dropdown arrow */
 
  
 
  /* Optional: Remove outline on focus */
  select:focus {
    outline: none;
    border-color: #007BFF; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow on focus */
  }
 