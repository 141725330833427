.ScheduleImageContainer{
    padding:150px 100px;  
    font-family: "Oswald", sans-serif; 
    color:#000000d0;
    border-top:3px solid black;
 
} 
.ScheduleTitle h1 {
  
    text-align: center;
    border-bottom: 3px solid black;
    font-size: 4em;
   
  }
  

.ScheduleImageContainer img{
    display:block;
    margin: 60px auto;
     
 
}
.DayOfTheWeek{
    border-bottom:2px solid black ;
}
.DayOfTheWeek h3{
    margin: 1rem 0;
    font-size: 2.5rem;
}
.DayOfTheWeek h4{
    margin-top: 1.5rem;
    font-size: 1.5rem;

}

 
.ClassNameAndTime{
    margin: 1rem 0;
    font-size: 1.2rem;
} 

.Sunday{
    border-bottom:none ;
}

.ScheduleText{
    width:50vw;
    margin: 4rem auto;
    margin-bottom: 1rem ;
    
}

@media(max-width:1200px){

    .DayOfTheWeek h3{
        margin: 1rem 0;
        font-size: 2.0rem;
    }
    .DayOfTheWeek h4{
        margin-top: 1.5rem;
        font-size: 1.2rem;
    
    }
    
     
    .ClassNameAndTime{
        margin: 1rem 0;
        font-size: 1.2rem;
    } 
    

    .ScheduleText{
        width:90vw;
    
    }
    .ScheduleImageContainer img{
        display:block;
        margin: 0 auto;
        width:100vw;
        height:auto;
    }

    .ScheduleImageContainer{
        padding:100px 0;  
    
}

.ScheduleTitle h1 {
  
 
    font-size: 3em;
   
  }}