.family-discount-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle at 30% 30%, rgb(255, 255, 255), rgba(255, 183, 0, 0.84));
    border-radius: 50%; /* Makes it a circle */
    transform: rotate(-10deg); /* Slightly slanted */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.689); /* Adds a nice shadow */
    padding: 20px;

    text-align: center;
    margin: 20px auto;
    margin-bottom:150px;
    transform: scale(0.3);
    transition: transform 2s ease-in-out ;
  
  }
  
  .family-discount-text {
    color: rgb(90, 2, 2);
    font-size: 1.6em;
    font-weight: bold;
    font-family: "Roboto Mono", monospace;
  }
  
  .large{
    transform: scale(1);
  }
  

  @media(max-width:800px){
    .Instructor{
        flex-direction: column;
}
.family-discount-badge {
  
  width: 250px;
  height: 250px;
  

}}