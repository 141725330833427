.PriceContainer{
    
    display: flex;
    flex-wrap: wrap;
    font-family: "Oswald", sans-serif;
    margin: 0 auto;
    padding:100px 0;
    width: fit-content; 
}

.PriceOuterContainer h1{
    font-family: Playfair Display;
    color: rgba(0, 0, 0, 0.096);
    text-align: right;
    margin-top:100px;
    width:50vw;
    font-size: 2em;
 
}

.Quote{
    padding:0 100px;
    transition: opacity 2s ease-in-out ;
    opacity: 0; 
    text-align: right;
   
    display: flex;
    justify-content: flex-end;
}

.Quote h1{
    font-size: 1rem;
}

 
.Quote.visible {
    opacity: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
 
 
 .PriceTable{
   
    display: flex;
    flex-direction: column;
 
    justify-content: space-around;   
 }

 .heading{
    font-size:1.7rem;
    margin: 20px auto;
   
 }


.PriceTable h1{
  
    font-family: "Oswald", sans-serif;

}

.PriceContainer h3{
    width: fit-content;
    font-size: 2.5rem;
    text-decoration: underline;
    margin-top: 30px;
    color:rgba(0, 0, 0, 0.81);
    
    padding:5px;
}

 
.Adult, .Kid{
    padding-bottom: 50px;
    width:40vw;
    border-bottom: 3px solid rgba(0, 0, 0, 0.444);
 
    
}

.disclaimer{
    opacity: 60%;
    width:65%;
    margin: 5em auto;
}

 

 

.Year{
   
    width: fit-content
    
}

 
.Upfront{
    height:fit-content;
    width:100%
}

.PriceContainer h4 {
    margin: 15px 0;
    color: rgba(0, 0, 0, 0.659);
}

 


.SecondColumn{
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.CoachNick{
    font-family:  "Oswald", sans-serif;
    color:black
}

.quote {
    
    z-index: 1;
    font-size: 2.5em;
    font-style: italic;
    text-align: left;
}

.Private{
    border-bottom: none;
}
.quote p {
    font-family: 'Georgia', serif;
    font-style: italic;
    font-size: 1em;
    text-align: right;
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.567);
}

.quote footer {
    text-align: right;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
}

.quote p::after {
    content: "\00a0\00a0"; /* Adds two non-breaking spaces */
    white-space: nowrap;  /* Prevent line breaks inside this pseudo-element */
}

.quote p span {
    display: inline-block;
    white-space: nowrap;
}


@media(max-width:800px){
    .FirstPart{
        margin:50px 0;
    }
 
    .PriceTable h1{
        text-align: right;
      
        width:100%;
        margin: 10px;
        margin-top: 40px;
        font-size: 1.3em;

    }

   
    .PriceOuterContainer{
        width: 80vw;
        margin: 0 auto;
      
    }
    .PriceContainer{
        width: 80vw;
        margin: 0 auto;
      
    }

    .Quote{
        padding:0
    }
    .Adult, .Kid{
        padding-bottom: 50px;
        width:100%;
    }
    .PriceOuterContainer h3 {
       text-align: center;
       font-size: 2.3rem;
    }
    
.Private{
    border-bottom: 3px solid rgba(0, 0, 0, 0.444);
 
}

.PriceOuterContainer h1{
    width:100%
}


  
}