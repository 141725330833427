.InstructorContainer {
    padding:150px 70px;
   
    font-family: "Oswald", sans-serif; 
    color:#000000d0;
    overflow: hidden;
}

.Crimson{
    color:rgb(192, 14, 50)
}
.InstructorText{
   
  
    margin: 0 auto;
    font-size: 2em;
    
} 

.InstructorText p{
   
  
    margin: 20px auto
    
} 
.Instructors{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    margin: 100px 0;
}

.InstructorContainer img{
height: 350px;
width: 270px;
border-radius:5%;
margin:0 auto;
padding:0;
display:block;
border: 1px solid black;
}

.Instructor{
    display: flex;
 
    margin:50px;
  

}

.InstructorName{
    font-size: 2em;
    
    text-align: left;}
/* Stylesheets/Belts.css */

.Belt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin:0;
    padding:0
   
}

.Belt div {
    width: 20px;
    height: 20px;
 
}

.black {
    background-color: black;
}

.red {
    background-color: red;
}

.brown {
    background-color: #8B4513
}

.BeltContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

.blackframe{
    border: 3px solid black;
}

 
.PictureAndBelt{
    opacity: 0;
    display:flex;
    transform: translateX(-100%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out ;
 
}

.Description{
    margin: 0 auto;  
    width: 50%;
    opacity: 0;
 
    transform: translateX(100%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out ;
}

 

.isVisible{
    opacity:1;
    transform:translateX(0)
}

.Description ul{
   margin-top:3em;
   width:100%;
   list-style:square;
 
}


 

@media(max-width:800px){
    .Instructor{
        flex-direction: column;
}
.InstructorContainer {
    padding:50px 10px;

}

.Instructors{
 
    margin: 20px 0;
}

.Instructor  {
    margin:60px auto;

}


.Description{
    width:80%;
    
}

.InstructorName{
    
    
    text-align: center;}
/* Stylesheets/Belts.css */

.Belt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0;
    padding:0
   
}
.Instructor{
    width:100%;
}
.InstructorText{
  
    margin: 0 auto;

    width:80vw;
    
} 
.InstructorText h2{
  
    font-size: 2.4rem;
} 

.InstructorText p{
  
    font-size: 1.2rem;
} 


}